<template>
  <json-viewer :value="user" boxed expanded></json-viewer>
</template>
<script setup>
import {useAuth} from "@/composables/useAuth";
import {ref} from "vue";

let auth = useAuth();
let user = ref({})

auth.$auth.getUser()
  .then(u => {
    user.value = u.profile
  })

</script>